import {useTranslation} from "react-i18next";
import {CustomCard} from "../../../../../UI/CustomCard";
import {Alert, Box, Button, Grid, IconButton, Stack, Typography} from "@mui/material";
import React, {memo, useCallback, useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {useFreelanceProfileStore} from "../../../../../../store/freelanceProfileStore";
import {
    MdAdd,
    MdAddCircleOutline,
    MdChevronLeft,
    MdChevronRight,
    MdDehaze,
    MdDelete,
    MdEdit,
    MdRemove
} from "react-icons/md";
import Popup from "../../../../../Popup/Popup";
import {
    createPortfolio,
    deletePortfolio,
    fetchFreelancePortfolios,
    fetchFreelancePortfolioTags,
    updatePortfolio, updatePortfolioDisplayOrder
} from "../../../../../../services/freelancePortfolioService";
import {FieldHolder} from "../../../../FieldHolder";
import Utils from "../../../../../../utils/Utils";
import Functions from "../../../../../../utils/Functions";
import {createFile} from "../../../../../../services/fileService";
import {Display} from "../../../../../Inputs/Display";
import "./freelanceportfoliocard.css";
import _, {debounce} from "lodash";
import {BsYoutube} from "react-icons/bs";
import videoService from "../../../../../../services/videoService";
import {DragDropContext, Draggable} from "react-beautiful-dnd";
import {StrictModeDroppable} from "../../../../../UI/StrictModeDroppable";
import Grid2 from "@mui/material/Unstable_Grid2";

export const FreelancePortfolioCard = ({formData}) => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);
    const {
        freelanceData,
        freelanceEditPermissions
    } = useFreelanceProfileStore();

    const freelanceProfileData = formData?.id ? formData : freelanceData;

    const [loading, setLoading] = useState(true);
    const [showDetailDialog, setShowDetailDialog] = useState(false);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [portfolios, setPortfolios] = useState([]);
    const [activePortfolio, setActivePortfolio] = useState(null);

    const fetchPortfoliosRef = useRef();

    const displayPortfolios = portfolios?.filter(portfolio => portfolio.id);
    const freelanceFirstName = freelanceProfileData?.User?.firstName;
    const title = t("Portfolio of {{freelanceFirstName}}", {freelanceFirstName: freelanceFirstName});


    useEffect(() => {
        fetchPortfoliosRef.current({});
    }, [freelanceProfileData?.id]);

    fetchPortfoliosRef.current = useCallback(async (query = {}) => {
        try {
            if (freelanceProfileData?.id) {
                setLoading(true);
                const fetchedData = await fetchFreelancePortfolios(freelanceProfileData.id, query, token);
                setPortfolios(fetchedData.data);
            }
        } catch (error) {
            console.error('Failed to fetch portfolios', error);
        } finally {
            setLoading(false);
        }
    }, [freelanceProfileData?.id, token]);

    const handlePortfolioClick = (portfolio) => {
        setActivePortfolio(portfolio);
        setShowDetailDialog(true);
    };

    const handleSaveFinish = () => {
        fetchPortfoliosRef.current({});
    };

    const getDetailDialogContent = () => {
        if (!displayPortfolios.length) return null;
        return (
            <Box sx={{width: {md: "65vw"}}}>
                <Stack direction="column" justifyContent="start" gap={3}>
                    <Typography variant="label">{title}</Typography>
                    <FreelancePortfolioDetail
                        freelanceProfile={freelanceProfileData}
                        setActivePortfolio={setActivePortfolio}
                        activePortfolio={activePortfolio}
                    />
                </Stack>
            </Box>
        );
    };

    const getEditDialogContent = () => (
        <Box sx={{width: {md: "40vw"}}}>
            <FreelancePortfolioForm
                freelanceProfile={freelanceProfileData}
                portfolios={portfolios}
                setPortfolios={setPortfolios}
                onSaveFinish={handleSaveFinish}
            />
        </Box>
    );

    return <>
        <CustomCard
            detailDialogContent={getDetailDialogContent()}
            editDialogContent={getEditDialogContent()}
            canModify={
                Boolean(displayPortfolios.length) &&
                freelanceEditPermissions.includes(Functions.FREELANCE_PROFILE_PERMISSION.FULL)
            }
        >
            {!loading && (
                <>
                    {displayPortfolios.length === 0 && (
                        <Stack direction="column"
                               alignItems="center"
                               justifyContent="center"
                               sx={{width: "100%", height: "100%"}}
                               spacing={3}
                        >
                            <Box id="addPortfolioBtn" onClick={() => setShowEditDialog(true)}>
                                <Button
                                    size="small"
                                    sx={{mr: 1}}
                                ><MdAdd size={20}/></Button>
                                {t("Add an image or video")}
                            </Box>

                            <Popup
                                open={showEditDialog}
                                onDialogClose={() => setShowEditDialog(false)}
                                dialogCloseIcon={<MdRemove/>}
                            >
                                {getEditDialogContent()}
                            </Popup>
                        </Stack>
                    )}
                    {displayPortfolios.length > 0 && (
                        <Box sx={{mt: 2, pr: 0.5, maxHeight: "328px", overflowY: "auto", width: "100%"}}>
                            <Grid container spacing={1}>
                                {displayPortfolios.map((portfolio, index) => (
                                    <Grid item key={index} xs={6} md={4}>
                                        <Box className="display-portfolio-item"
                                             title={portfolio.title}
                                             sx={{cursor: "pointer"}}
                                             onClick={() => handlePortfolioClick(portfolio)}
                                        >
                                            <Display
                                                type="image"
                                                value={getPortfolioThumbnail(portfolio)}
                                            />
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                            <Popup
                                open={showDetailDialog}
                                onDialogClose={() => setShowDetailDialog(false)}
                                dialogCloseIcon={<MdRemove/>}
                            >
                                {getDetailDialogContent()}
                            </Popup>
                        </Box>
                    )}
                </>
            )}
        </CustomCard>
    </>
};

export const FreelancePortfolioDetail = ({freelanceProfile, activePortfolio, setActivePortfolio}) => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);

    const pageSize = 9;
    const [filterConfigs, setFilterConfigs] = useState({
        filter: '',
        range: JSON.stringify([0, pageSize - 1]),
    });
    const [portfolios, setPortfolios] = useState([]);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageCount: 0
    });

    const fetchPortfoliosRef = useRef();

    useEffect(() => {
        fetchPortfoliosRef.current(filterConfigs);
    }, [filterConfigs]);

    fetchPortfoliosRef.current = async (query = {}) => {
        try {
            if (freelanceProfile?.id) {
                const fetchedData = await fetchFreelancePortfolios(freelanceProfile.id, query, token);
                if (!fetchedData?.error) {
                    setPortfolios(fetchedData.data);
                    setPaginationModel({...paginationModel, pageCount: Math.floor(fetchedData.count / pageSize)});
                    if (!activePortfolio) {
                        setActivePortfolio(fetchedData.data[0]);
                    }
                }
            }
        } catch (error) {

        }
    };

    const handlePaginationChange = (params) => {
        setPaginationModel(params);

        const start = (params.page * pageSize);
        const end = start + (pageSize - 1);

        setFilterConfigs({
            ...filterConfigs,
            range: JSON.stringify([start, end])
        });
    };

    return <>
        <Grid id="freelancePortfolioDetail" container spacing={6}>
            <Grid item xs={12} md={6}>
                <Stack direction="column" alignItems="center" gap={3}>
                    <Grid container
                          justifyContent="center"
                          spacing={1}
                    >
                        {portfolios.map((portfolio, index) => {
                            const isActive = activePortfolio && activePortfolio.id === portfolio.id;
                            return (
                                <Grid item key={index} xs={6} md={4}>
                                    <Box
                                        className={`display-portfolio-item ${isActive ? "active" : ""}`}
                                        sx={{cursor: "pointer"}}
                                        onClick={() => setActivePortfolio(portfolio)}
                                    >
                                        <Display
                                            type="image"
                                            value={getPortfolioThumbnail(portfolio)}
                                        />
                                    </Box>
                                </Grid>
                            )
                        })}
                    </Grid>
                    {paginationModel.pageCount > 0 && (
                        <Stack direction="row" alignItems="center">
                            <IconButton
                                title={t("Previous")}
                                disabled={paginationModel.page === 0}
                                onClick={() =>
                                    handlePaginationChange({
                                        page: paginationModel.page - 1,
                                        pageCount: paginationModel.pageCount
                                    })
                                }
                                size="small"
                            >
                                <MdChevronLeft size={30}/>
                            </IconButton>
                            <IconButton
                                title={t("Next")}
                                disabled={paginationModel.page === paginationModel.pageCount}
                                onClick={() =>
                                    handlePaginationChange({
                                        page: paginationModel.page + 1,
                                        pageCount: paginationModel.pageCount
                                    })
                                }
                                size="small"
                            >
                                <MdChevronRight size={30}/>
                            </IconButton>
                        </Stack>
                    )}
                </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
                {activePortfolio && (
                    <Stack direction="column" gap={2}>
                        <Box className="display-portfolio-preview">
                            <Display
                                type="image"
                                value={getPortfolioThumbnail(activePortfolio)}
                                displaySkeleton={true}
                            />
                        </Box>
                        <Display
                            label={
                                activePortfolio.type === "video" ? t("Video title") : t("Image title")
                            }
                            value={activePortfolio.title}
                        />
                        {activePortfolio.type === "video" && (
                            <Display
                                type="url"
                                label={t("Video URL")}
                                value={activePortfolio.videoUrl}
                            />
                        )}
                        <Display
                            type="dropdown"
                            ismulti={true}
                            label={t("Tags")}
                            value={activePortfolio.PortfolioTags}
                            size="small"
                        />
                    </Stack>
                )}
            </Grid>
        </Grid>
    </>
};

export const FreelancePortfolioForm = ({freelanceProfile, portfolios, setPortfolios, onSaveFinish}) => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);

    const [tags, setTags] = useState([]);
    const [files, setFiles] = useState({});

    const defaultValue = {
        FreelanceProfileId: freelanceProfile?.id,
        type: '',
        file: null,
        videoUrl: null,
        thumbnailUrl: null,
        PortfolioTags: []
    };
    const freelanceFirstName = freelanceProfile?.User?.firstName;

    useEffect(() => {
        (async () => {
            if (freelanceProfile?.id) {
                fetchFreelancePortfolioTags(freelanceProfile.id, {}, token).then((fetchedData) => {
                    if (!fetchedData?.error) {
                        setTags(fetchedData.data);
                    }
                });
            }
        })();
    }, [token]);

    const handleFileChange = (portfolioData, name, fileData) => {
        const updatePortfolios = [...portfolios];
        let selectedFiles = {...files};
        const portfolioIndex = portfolioData.index;
        if (fileData) {
            selectedFiles = Utils.updateNestedField(selectedFiles, `${portfolioIndex}.${name}`, fileData);
            updatePortfolios[portfolioIndex][name] = true;
        } else {
            delete selectedFiles[portfolioIndex][name];
            updatePortfolios[portfolioIndex][name] = false;
        }
        setFiles(selectedFiles);
        setPortfolios(updatePortfolios);
    };

    const handleChange = async (portfolioData, name, value) => {
        const updatePortfolios = [...portfolios];
        updatePortfolios[portfolioData.index][name] = value;
        setPortfolios(updatePortfolios);

        if (name === "videoUrl") {
            await handleVideoUrlChange(portfolioData, value);
        }
    };

    const handleVideoUrlChange = useCallback(
        debounce(async (portfolioData, videoUrl) => {
            const updatePortfolios = [...portfolios];
            portfolioData.thumbnailUrl = await videoService.getVideoThumbnailUrl(videoUrl);
            updatePortfolios[portfolioData.index] = portfolioData;
            setPortfolios(updatePortfolios);
        }, 300), // 300ms debounce time
        [portfolios]
    );

    const handleCreate = (type) => {
        const data = {...defaultValue, type: type};
        const updatePortfolios = [...portfolios];
        updatePortfolios.unshift(data);
        setPortfolios(updatePortfolios);
    };

    const handleEdit = (portfolioData) => {
        const updatePortfolios = [...portfolios];
        portfolioData.editMode = true;
        updatePortfolios[portfolioData.index] = portfolioData;
        setPortfolios(updatePortfolios);
    };

    const handleSave = async (portfolioData) => {
        const updatePortfolios = [...portfolios];
        try {
            portfolioData.processing = true;

            if (typeof files[portfolioData.index] !== "undefined") {
                const portfolioFile = files[portfolioData.index].file;
                const fileData = {
                    typeFile: Functions.TEXT_PROFILE_PICTURE,
                    file: portfolioFile.theFile,
                };
                const result = await createFile(fileData, "", token);
                portfolioData.file = !result.error ? result : null;
            }

            let response;
            if (portfolioData?.id) {
                response = await updatePortfolio(portfolioData.id, portfolioData, token);
            } else {
                response = await createPortfolio(portfolioData, token);
                if (!response?.error) {
                    portfolioData.id = response.id;
                }
            }
            portfolioData.processing = false;

            if (!response?.error) {
                portfolioData.editMode = false;
                onSaveFinish && onSaveFinish();
            }

            updatePortfolios[portfolioData.index] = portfolioData;
            setPortfolios(updatePortfolios);
        } catch (error) {
        }
    };

    const handleCancel = (portfolioData) => {
        const updatePortfolios = [...portfolios];
        if (portfolioData.id) {
            portfolioData.editMode = false;
            updatePortfolios[portfolioData.index] = portfolioData;
        } else {
            updatePortfolios.splice(portfolioData.index, 1);
        }
        setPortfolios(updatePortfolios);
    };

    const handleDelete = async (portfolio) => {
        const response = await deletePortfolio(portfolio.id, token);
        if (!response?.error) {
            const updatePortfolios = [...portfolios];
            updatePortfolios.splice(portfolio.index, 1);
            setPortfolios(updatePortfolios);
        }
    };

    const handleOnDragEnd = async (result) => {
        if (!result.destination) return;

        const items = Array.from(portfolios);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setPortfolios(items);

        const portfolioIds = items?.filter(item => item?.id).map(item => item.id);
        await updatePortfolioDisplayOrder({ids: portfolioIds}, token);
    };

    return <>
        <Stack direction="column" justifyContent="start" spacing={2}>
            <Typography variant="label">
                {t("Portfolio of {{freelanceFirstName}}", {freelanceFirstName: freelanceFirstName})}
            </Typography>
            <Stack direction="row" spacing={1}>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleCreate("image")}
                    size="small"
                >
                    {t("Add an image")}
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleCreate("video")}
                    size="small"
                >
                    {t("Add a video")}
                </Button>
            </Stack>
            {portfolios.length === 0 && (
                <Alert icon={false} severity="info" sx={{width: '100%'}}>
                    {t("No portfolio added")}
                </Alert>
            )}
            {portfolios.length > 0 && (
                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <StrictModeDroppable droppableId="portfolios">
                        {(provided) => (
                            <div
                                className="portfolios"
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "start",
                                    maxHeight: "63vh",
                                    overflowY: "auto",
                                    paddingRight: "5px"
                                }}
                            >
                                {portfolios.map((portfolio, index) => {
                                    portfolio.index = index;
                                    portfolio.processing = portfolio.processing ?? false;
                                    if (!portfolio?.editMode) {
                                        portfolio.editMode = !Boolean(portfolio?.id);
                                    }
                                    return (
                                        <Draggable
                                            key={portfolio.id ?? `tmp${index}`}
                                            draggableId={portfolio.id ?? `tmp${index}`}
                                            index={index}
                                            isDragDisabled={Boolean(!portfolio.id)}
                                        >
                                            {(provided) => (
                                                <FreelancePortfolioRow
                                                    provided={provided}
                                                    index={index}
                                                    portfolio={portfolio}
                                                    tags={tags}
                                                    onChange={handleChange}
                                                    onFileChange={handleFileChange}
                                                    onEdit={handleEdit}
                                                    onSave={handleSave}
                                                    onCancel={handleCancel}
                                                    onDelete={handleDelete}
                                                />
                                            )}
                                        </Draggable>
                                    );
                                })}
                                {provided.placeholder}
                            </div>
                        )}
                    </StrictModeDroppable>
                </DragDropContext>
            )}
        </Stack>
    </>
};

export const FreelancePortfolioRow = (
    {
        provided,
        portfolio,
        tags,
        onChange,
        onFileChange,
        onEdit,
        onSave,
        onCancel,
        onDelete
    }
) => {
    const {t} = useTranslation();

    const [formErrors, setFormErrors] = useState({});

    const editMode = portfolio.editMode;
    const index = portfolio.index;

    const imageTextUpload = <>
        <Stack
            direction="column"
            alignItems="center"
            gap={2}
        >
            <Typography sx={{
                textAlign: "center",
                cursor: "pointer"
            }}>{t("Click to upload an image")}</Typography>
            <MdAddCircleOutline size={20}/>
        </Stack>
    </>;

    useEffect(() => {
        if (editMode) {
            setFormErrors({});
        }
    }, [editMode]);

    const handleChange = (e) => {
        const {name, value} = e.target;
        onChange && onChange(portfolio, name, value);
    };

    const handleFileChange = (name, value) => {
        onFileChange && onFileChange(portfolio, name, value);
    };

    const handleDropdownChange = (name, selectedValue) => {
        onChange && onChange(portfolio, name, selectedValue);
    };

    const handleSave = async () => {
        let requiredFields;
        if (portfolio.type === "video") {
            requiredFields = ['title', 'videoUrl', 'thumbnailUrl'];
        } else {
            requiredFields = ['title', 'file'];
        }

        let {valid, errors} = Utils.validateRequiredFields(portfolio, requiredFields);
        setFormErrors(errors);

        if (!valid) {
            return;
        }

        onSave && onSave(portfolio);
    };

    return <>
        <Grid
            ref={provided.innerRef}
            {...provided.draggableProps}
            container
            className="portfolio-item"
            alignItems="center"
            gap={2.5}
        >
            {portfolio?.id && (
                <Grid item xs="auto" {...provided.dragHandleProps}>
                    <MdDehaze
                        color="primary"
                        size={20}
                        style={{cursor: "pointer", color: "rgba(0, 0, 0, 0.5)"}}
                    />
                </Grid>
            )}
            <Grid item xs container gap={2.5}>
                <Grid item xs="auto">
                    {portfolio.type === "video"
                        ? (
                            <Box className="box-file" sx={{backgroundColor: "rgba(0, 0, 0, 0.08)", cursor: "default"}}>
                                <>
                                    {portfolio.thumbnailUrl
                                        ? <img src={portfolio.thumbnailUrl} className="file-image"/>
                                        : <BsYoutube size={45} style={{color: "rgba(0, 0, 0, 0.5)"}}/>
                                    }
                                </>
                            </Box>
                        )
                        : (
                            <FieldHolder
                                type="image"
                                name="file"
                                id={`porfolioFile${index}`}
                                value={portfolio.file}
                                onFileChange={handleFileChange}
                                resizeImage={false}
                                textUpload={imageTextUpload}
                                canModify={editMode}
                                error={formErrors.file}
                            />
                        )
                    }
                </Grid>
                <Grid item xs>
                    <Grid container gap={2}>
                        <Grid item xs container direction="column" gap={1.5}>
                            <FieldHolder
                                fielddesc={
                                    portfolio.type === "video" ? t("Video title") : t("Image title")
                                }
                                type="text"
                                name="title"
                                id={`porfolioTitle${index}`}
                                value={portfolio.title}
                                onChange={handleChange}
                                canModify={editMode}
                                mandatoryFlag={true}
                                error={formErrors.title}
                            />
                            {portfolio.type === "video" && (
                                <FieldHolder
                                    fielddesc={t("Video URL")}
                                    type="url"
                                    name="videoUrl"
                                    id={`porfolioVideoUrl${index}`}
                                    value={portfolio.videoUrl}
                                    onChange={handleChange}
                                    canModify={editMode}
                                    mandatoryFlag={true}
                                    error={formErrors.videoUrl || formErrors.thumbnailUrl}
                                />
                            )}
                            <FieldHolder
                                fielddesc={t("Tags")}
                                type="dropdown"
                                name="PortfolioTags"
                                id={`porfolioTags${index}`}
                                value={portfolio?.PortfolioTags}
                                datas={tags}
                                onDropdownChange={handleDropdownChange}
                                canModify={editMode}
                                ismulti={true}
                                allowAdd={true}
                            />
                        </Grid>
                        <Grid item xs="auto">
                            {portfolio.id && !portfolio.editMode
                                ? (
                                    <Stack direction="row">
                                        <IconButton
                                            title={t("Modify")}
                                            onClick={() => onEdit && onEdit(portfolio)}
                                            size="small"
                                        >
                                            <MdEdit size={20}/>
                                        </IconButton>
                                        <IconButton
                                            title={t("Delete")}
                                            onClick={() => onDelete && onDelete(portfolio)}
                                            size="small"
                                            color="secondary"
                                        >
                                            <MdDelete size={20}/>
                                        </IconButton>
                                    </Stack>
                                )
                                : (
                                    <Stack direction="column" spacing={1}>
                                        <Button
                                            disabled={portfolio?.processing}
                                            variant="contained"
                                            color="secondary"
                                            size="small"
                                            onClick={handleSave}
                                            sx={{minWidth: "8rem"}}
                                        >{portfolio.id ? t("Save") : t("Add")}</Button>
                                        <Button
                                            variant="outlined"
                                            color="light"
                                            size="small"
                                            onClick={() => onCancel && onCancel(portfolio)}
                                            sx={{minWidth: "8rem"}}
                                        >{t("Cancel")}</Button>
                                    </Stack>
                                )
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </>
};

const getPortfolioThumbnail = (portfolioData) => {
    return portfolioData.type === "video" ? portfolioData.thumbnailUrl : portfolioData.file;
};