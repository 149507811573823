import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {AppLayout} from "../../../components/UI/AppLayout";
import Functions from "../../../utils/Functions";
import {fetchOffers} from "../../../services/recruitment";
import {Table} from "../../../components/Tables/Table";
import {useNavigate} from "react-router-dom";
import {Box, Button, Typography} from "@mui/material";
import Utils from "../../../utils/Utils";

const pageSize = parseInt(process.env.REACT_APP_TABLE_DEFAULT_PAGE_SIZE);

export const JobApplicationBoard = () => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);
    const connectedUser = useSelector((state) => state.auth);
    const navigate = useNavigate();

    const tableRef = useRef();
    const [rowCount, setRowCount] = useState(0);
    const [data, setData] = useState([]);
    const defaultFilterConfigs = {
        sort: '',
        range: JSON.stringify([0, pageSize - 1]),
        filter: ''
    };
    const [filterConfigs, setFilterConfigs] = useState(defaultFilterConfigs);
    const isEnterpriseSession = connectedUser.sessionType === Functions.SESSION_TYPE_ENTERPRISE;

    const columns = useMemo(() => {
        return [
            {
                field: 'RecruitmentOffer.title',
                type: 'string',
                headerName: t("Offer title"),
                minWidth: 250,
                flex: 2.5,
                headerAlign: 'center',
                align: 'left',
                renderCell: ({row}) => {
                    return row.title;
                }
            },
            {
                field: 'applicationCount',
                type: 'number',
                noSearchFilter: true,
                headerName: t("Total candidates"),
                minWidth: 200,
                flex: 2.0,
                headerAlign: 'center',
                align: 'center',
                renderCell: ({row}) => {
                    return (
                        <Typography>
                            {parseInt(row.applicationCount) > 0 ? row.applicationCount : "-"}
                        </Typography>
                    );
                }
            },
            {
                field: 'newApplicationCount',
                type: 'number',
                noSearchFilter: true,
                headerName: t("New candidates"),
                minWidth: 200,
                flex: 2.0,
                headerAlign: 'center',
                align: 'center',
                renderCell: ({row}) => {
                    return (
                        <Typography color="secondary">
                            {parseInt(row.newApplicationCount) > 0 ? row.newApplicationCount : "-"}
                        </Typography>
                    );
                }
            },
            {
                field: 'lastApplicationDate',
                type: 'date',
                headerName: t("Last application date"),
                minWidth: 200,
                flex: 2.0,
                headerAlign: 'center',
                align: 'center',
                valueGetter: (params, row) => row.lastApplicationDate && new Date(row.lastApplicationDate),
                renderCell: ({row}) => {
                    return row.lastApplicationDate ? Utils.formatDate(row.lastApplicationDate) : "-";
                }
            },
        ];
    }, []);

    const loadOffers = useCallback((query = {}) => {
        try {
            if (isEnterpriseSession) {
                fetchOffers(query, token).then((fetchedData) => {
                    if (!fetchedData?.error) {
                        setRowCount(fetchedData.count);
                        setData(fetchedData.data);
                    }
                });
            }
        } catch (error) {
            console.error(error);
        }
    }, [token, isEnterpriseSession]);

    useEffect(() => {
        loadOffers(filterConfigs);
    }, [filterConfigs, loadOffers]);

    const handleCellClick = ({id}) => {
        navigate(`/recruitment/applications/detail/${id}`);
    };

    const handleDataGridChange = (filterConfigs) => {
        setFilterConfigs(filterConfigs);
    };

    return (
        <AppLayout className="content-full-height">
            <Box className="pageheader">
                <h1 className="pagetitle">
                    {t("Job applications")}
                    <p className="header-data-row-count">{rowCount}</p>
                </h1>
                <div className="pageaction">
                    <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        onClick={() => navigate('/recruitment/offers/detail')}
                    >
                        {t("Create a new offer")}
                    </Button>
                </div>
            </Box>
            <Box className="table-layout">
                <Table
                    ref={tableRef}
                    columns={columns}
                    data={data}
                    rowCount={rowCount}
                    pageSize={pageSize}
                    filterConfigs={filterConfigs}
                    onChange={handleDataGridChange}
                    onCellClick={handleCellClick}
                />
            </Box>
        </AppLayout>
    );
};