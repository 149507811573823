import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';

import "../page.css";

import {fetchFreelanceProfile} from "../../services/freelanceProfileService";
import {AppLayout} from "../../components/UI/AppLayout";
import {FreelanceProfileForm} from "../../components/Forms/FreelanceProfile/FreelanceProfileForm";
import {Box, Button, Stack} from "@mui/material";
import Popup from "../../components/Popup/Popup";
import EnterpriseCollaborationRequestForm
    from "../../components/Forms/EnterpriseCollaborationRequest/EnterpriseCollaborationRequestForm";
import Functions from "../../utils/Functions";
import ResponsiveComponent from "../../components/UI/ResponsiveComponent";
import {useFreelanceProfileStore} from "../../store/freelanceProfileStore";
import PermissionService from "../../services/permissionService";
import {FreelanceAdding} from "../../components/Forms/FreelanceProfile/FreelanceCreation/FreelanceAdding";
import CopyLinkButton from "../../components/Button/CopyLinkButton";
import {useFormStore} from "../../store/formStore";


export const FreelanceProfile = ({freelanceProfileId, application = {}}) => {
    const {t} = useTranslation();
    const {id} = useParams();

    const {
        setFreelanceData, setFreelanceApplication,
        freelanceEditPermissions, setFreelanceEditPermissions
    } = useFreelanceProfileStore();
    const {referenceAccessData, setReferenceAccessData} = useFormStore();

    const [activeSection, setActiveSection] = useState(Functions.FREELANCE_PROFILE_SECTION.PROFILE);
    const [editingMode, setEditingMode] = useState("");
    const [editingData, setEditingData] = useState({});

    const token = useSelector((state) => state.auth.token);
    const connectedUser = useSelector((state) => state.auth);
    const freelanceId = connectedUser?.freelanceId;
    const companyId = connectedUser.companyId;
    const sessionType = connectedUser.sessionType;

    const fetchedFreelanceId = freelanceProfileId
        ? freelanceProfileId : (typeof id !== "undefined" ? id : freelanceId); // FreelanceProfileId

    const [firstLastName, setFirstLastName] = useState("");
    const [freelanceEmail, setFreelanceEmail] = useState("");
    const [triggerApi, setTriggerApi] = useState(true);
    const isFreelanceSession = sessionType === Functions.SESSION_TYPE_FREELANCE;
    const isEnterpriseSession = sessionType === Functions.SESSION_TYPE_ENTERPRISE;

    useEffect(() => {
        setFreelanceApplication(application);

        return () => {
            setFreelanceApplication({});
            setReferenceAccessData({
                referenceAccessRequestCode: false,
                referenceAccessAuthorization: false,
            });
        };
    }, []);

    useEffect(() => {
        // Get the freelance profile to display
        try {
            if (triggerApi && fetchedFreelanceId) {
                fetchFreelanceProfile(fetchedFreelanceId, token, true).then(fetchedFreelanceProfile => {
                    if (!fetchedFreelanceProfile?.error) {
                        setFirstLastName(`${fetchedFreelanceProfile.User.fullName}`);
                        setFreelanceEmail(`${fetchedFreelanceProfile.User.email}`);
                        setFreelanceData(fetchedFreelanceProfile);

                        // If the freelance profile is the logged-in user
                        // Or the user belongs to the company that created this profile
                        // and that the freelance didn't log in his account yet than the user can modify the profile
                        setFreelanceEditPermissions(checkFreelanceEditPermissions(fetchedFreelanceProfile));

                        if (fetchedFreelanceProfile?.EnterpriseCollaborationRequests?.length) {
                            const request = fetchedFreelanceProfile?.EnterpriseCollaborationRequests[0];
                            setReferenceAccessData({
                                ...referenceAccessData,
                                id: request.id,
                                referenceAccessRequestCode: request?.referenceAccessRequestCode ?? false,
                                referenceAccessAuthorization: request?.referenceAccessAuthorization ?? false,
                                firstName: connectedUser?.userFirstName,
                                lastName: connectedUser?.userLastName,
                                email: connectedUser?.userEmail,
                                companyName: connectedUser?.companyName
                            });
                        }

                        setTriggerApi(false);
                    }
                });
            }
        } catch (error) {
            console.error(error);
        }
    }, [fetchedFreelanceId, token, triggerApi]);

    const checkFreelanceEditPermissions = (data) => {
        const isFreelanceUser = isFreelanceSession && fetchedFreelanceId === data.id;
        const hasEnterpriseEditPermission = isEnterpriseSession &&
            PermissionService.hasPermission(connectedUser, "editFreelance") &&
            (companyId === data.creator_company || connectedUser?.fullAccess);

        if (isFreelanceUser || hasEnterpriseEditPermission) {
            return [Functions.FREELANCE_PROFILE_PERMISSION.FULL];
        }

        return isEnterpriseSession && data?.CompanyToFreelanceRelations.length > 0
            ? [Functions.FREELANCE_PROFILE_PERMISSION.NOTE] : [];
    };

    const handleCollaborationRequestCreate = () => {
        setEditingData({
            email: null,
            companyName: "",
            firstName: "",
            lastName: "",
            language: "",
            companyMessage: "",
        })
        setEditingMode("CollaborationRequest");
    };

    const handleAddingFreelanceSaveFinish = (success) => {
        if (success) {
            setTriggerApi(true);
            handleDialogClose();
        }
    };

    const handleCollaborationRequestSaveFinish = (success) => {
        if (success) {
            handleDialogClose();
        }
    };

    const handleDialogClose = () => {
        setEditingMode("");
    };

    let button;
    const pageHeaderStyle = {};
    if (isEnterpriseSession) {
        if (!triggerApi && !freelanceEditPermissions.length) {
            pageHeaderStyle.width = {xs: '100%', md: 'calc(57% + 302px)'};
            button = (
                <>
                    <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        onClick={() => setEditingMode("FreelanceAdding")}
                    >
                        {t("Add the freelancer to my pool")}
                    </Button>
                    <Popup
                        open={editingMode === "FreelanceAdding"}
                        onDialogClose={handleDialogClose}
                    >
                        <FreelanceAdding
                            email={freelanceEmail}
                            addingSource={application?.id ? "job_application" : ""}
                            onSaveFinish={handleAddingFreelanceSaveFinish}
                            onDialogClose={handleDialogClose}
                        />
                    </Popup>
                </>
            );
        }
    } else if (activeSection === Functions.FREELANCE_PROFILE_SECTION.PROFILE) {
        button = (
            <Stack direction="row" gap={2}>
                <CopyLinkButton
                    link={`${window.location.origin}/profile/${fetchedFreelanceId}`}
                    label="Copy the link to my profile"
                    variant="contained"
                    size="large"
                />
                <>
                    <Button
                        variant="outlined"
                        color="secondary"
                        size="large"
                        onClick={handleCollaborationRequestCreate}
                    >
                        {t("Sharing my information and documents")}
                    </Button>
                    <Popup
                        title={t("Sharing my information and documents")}
                        open={editingMode === "CollaborationRequest"}
                        onDialogClose={handleDialogClose}
                    >
                        <EnterpriseCollaborationRequestForm
                            collaborationRequest={editingData}
                            onSaveFinish={handleCollaborationRequestSaveFinish}
                        />
                    </Popup>
                </>
            </Stack>
        );
    }

    return (
        <AppLayout>
            <Box className="pageheader" sx={pageHeaderStyle}>
                <Stack
                    alignItems="start"
                    justifyContent="space-between"
                    sx={{
                        flexDirection: {xs: "column", md: "row"},
                        width: {xs: "100%", md: "40%"}
                    }}
                >
                    <p className="pagetitle">{firstLastName ?? ""}</p>
                    <ResponsiveComponent mobile>
                        <div className="pageaction">{button}</div>
                    </ResponsiveComponent>
                </Stack>
                <ResponsiveComponent tablet desktop>
                    <Box className="pageaction" sx={{minHeight: "54px"}}>{button}</Box>
                </ResponsiveComponent>
            </Box>
            <FreelanceProfileForm
                editPermissions={freelanceEditPermissions}
                activeSection={activeSection}
                setActiveSection={setActiveSection}
            />
        </AppLayout>
    );
};
