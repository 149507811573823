// Définir les types d'action comme des constantes
const TEMPORARY_TOKEN = 'TEMPORARY_TOKEN';
const SAVE_TOKEN = 'SAVE_TOKEN';
const UPDATE_DISPLAY_CONFIG = 'UPDATE_DISPLAY_CONFIG';
const LOGOUT = 'LOGOUT';

const initialState = {
    token: null,
    userId: null,
    userFirstName: null,
    userLastName: null,
    userEmail: null,
    freelanceId: null,
    enterpriseId: null,
    companyId: null,
    companyName: null,
    companyRole: null,
    sessionType: null,
    displayConfig: {},
    fullAccess: false,
    isAuthenticated: false,
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case TEMPORARY_TOKEN:
            return {
                ...state,
                token: action.payload,
                freelanceId: action.freelanceId,
                sessionType: action.userType,
                isAuthenticated: false,
            };
        case SAVE_TOKEN:
            return {
                ...state,
                token: action.payload,
                userId: action.userId,
                userFirstName: action.userFirstName,
                userLastName: action.userLastName,
                userEmail: action.userEmail,
                freelanceId: action.freelanceId,
                enterpriseId: action.enterpriseId,
                companyId: action.companyId,
                companyName: action.companyName,
                companyRole: action.companyRole,
                sessionType: action.userType,
                displayConfig: action.displayConfig ? JSON.parse(action.displayConfig) : {},
                fullAccess: action.fullAccess,
                isAuthenticated: true,
            };
        case UPDATE_DISPLAY_CONFIG:
            return {
                ...state,
                displayConfig: action.displayConfig ? JSON.parse(action.displayConfig) : {},
            };
        case LOGOUT:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

export default authReducer;
