class Functions {
    static TEXT_DESCRIPTION = "description";
    static TEXT_DETAILS = "details";
    static TEXT_MESSAGE = "message";
    static TEXT_CUSTOMER = "Customer";
    static SESSION_TYPE_FREELANCE = "Freelance";
    static SESSION_TYPE_ENTERPRISE = "Enterprise";

    static SECTION_DETAIL_MISSION = "detail_mission";
    static SECTION_CONTRACTUALISATION = "contractualisation";
    static SECTION_FREELANCE = "freelance";
    static SECTION_QUOTE = "quote";
    static SECTION_PAYMENT_METHOD = "payment_method";
    static SECTION_CONTRACT = "contract";
    static SECTION_ONBOARDING = "onboarding";
    static SECTION_INVOICE_AND_PAYMENT = "invoice_payment";
    static SECTION_FREELANCE_EVALUATION = "freelance_evaluation";
    static SECTION_FREELANCE_SATISFACTION = "freelance_satisfaction";

    static TEXT_SELECTION = "selection";
    static TEXT_SEARCH = "search";
    static TEXT_PERSONAL_INFO_STEP = "PersonalInformationStep";
    static TEXT_VALIDATION_STEP = "ValidationStep";

    static TEXT_QUOTE_ONLY = "mission_quote_only";
    static TEXT_QUOTE_CONTRAT = "mission_quote_contrat";
    static TEXT_CONTRAT_ONLY = "mission_contrat_only";
    static TEXT_CONTRAT = "contract";
    static TEXT_AMENDMENT = "amendment";

    static TEXT_SMARTEEM_CONTRACT = "smarteem_contract";
    static TEXT_CUSTOM_CONTRACT = "custom_contract";
    static TEXT_STANDARD_PAYMENT_METHOD = "standard_payment_method";
    static TEXT_CUSTOM_PAYMENT_METHOD = "custom_payment_method";

    static TEXT_CDI = "cdi";
    static TEXT_CDD = "cdd";
    static TEXT_CREATION = "creation";

    static TEXT_QUOTE_CREATION = "creation_devis";
    static TEXT_QUOTE_SIGNATURE = "signature_devis";

    static TEXT_MAIN_TYPE_ENTREPRISE = "Enterprise";
    static TEXT_ERROR_SAVE = "Erreur lors de l'enregistrement des données.";


    static TEXT_CLOSED = "Closed";
    static TEXT_VALIDATED = "Validated";
    static TEXT_PAID = "Paid";
    static TEXT_OPEN = "Open";
    static TEXT_TO_SIGN = "To sign";
    static TEXT_INVALID = "Invalid";
    static TEXT_CANCELED = "Canceled";
    static TEXT_RECEIVED = "Received";
    static TEXT_REFUSED = "Refused";
    static TEXT_REJECTED = "Rejected";
    static TEXT_SIGNED = "Signed";
    static TEXT_PENDING = "Pending";
    static TEXT_FREELANCE_SIGNATURE = "Freelance signature";
    static TEXT_ENTERPRISE_SIGNATURE = "Enterprise signature";
    static TEXT_PROFILE_PICTURE = "PROFILE_PICTURE";
    static TEXT_DOCUMENT = "DOCUMENT";

    static USER_ROLE = {
        SUPER_ADMINISTRATOR: "Super administrator",
        ADMINISTRATOR: "Administrator",
        PAYER: "Payer",
        READER: "Reader"
    };

    static MISSION_FORMAT = {
        OPERATIONAL: "mission_operational",
        CONTRACT_INVOICE: "mission_contract_invoice",
        CONTRACT_WITHOUT_INVOICE: "mission_contract_without_invoice",
    };

    static STATUS_MISSION = {
        Archived: "Archived",
        Closed: "Closed",
        Deleted: "Deleted",
        "In progress": "In progress",
        Open: "Open",
    };

    static STATUS_INVOICE_BG = {
        Open: "tag-invoice open",
        Validated: "tag-invoice validated",
        Canceled: "tag-invoice canceled",
        Rejected: "tag-invoice rejected",
        Paid: "tag-invoice paid",
        Invalid: "tag-invoice invalid",
    };

    static STATUS_QUOTE_BG = {
        Open: "tag-quote open",
        Refused: "tag-quote refused",
        Rejected: "tag-quote rejected",
        Signed: "tag-quote signed",
    };

    static STATUS_CONTRACT_BG = {
        "Freelance signature": "tag-contract freelance-signature",
        "Enterprise signature": "tag-contract enterprise-signature",
        Canceled: "tag-contract canceled",
        "To sign": "tag-contract tosign",
        Signed: "tag-contract signed",
    };

    static STATUS_FEEDBACK_BG = {
        Pending: "tag-satisfaction pending",
        Validated: "tag-satisfaction validated",
    };

    static STATUS_EVALUATION_BG = {
        Enterprise: "tag-evaluation enterprise",
        Customer: "tag-evaluation customer",
    };

    static STATUS_INVOICE = {
        Open: "Waiting validation",
        Validated: "Invoice.Validated",
        Canceled: "Invoice.Canceled",
        Paid: "Paid",
        Rejected: "Invoice.Rejected"
    };

    static STATUS_QUOTE = {
        Open: "Pending signature",
        Rejected: "Quote.Rejected",
        Signed: "Signed",
    };

    static STATUS_CONTRACTS = {
        Canceled: "Canceled",
        "To sign": "To be signed",
        Signed: "Signed",
        "Freelance signature": "Freelance signature",
        "Enterprise signature": "Enterprise signature",
    };

    static STATUS_FEEDBACK = {
        Pending: "Pending",
        Validated: "Received",
    };

    static STATUS_OFFER = {
        New: "New",
        "In progress": "In progress 2",
        "Published": "Published",
        Closed: "Closed 2",
    };

    static SKILL_TYPE = {
        TECHNICAL: "technical",
        TRANSVERSAL: "transversal",
        BUSINESS: "business_line",
        LANGUAGE: "language",
    };

    static TAGS_TYPE = {
        FREELANCE_TAG: "freelance_tag",
        FREELANCE_REPORTING_ENTITY: "freelance_reporting_entity",
        FREELANCE_LEGAL_ENTITY: "freelance_legal_entity",
        FREELANCE_COUNTRY: "freelance_country",
        MISSION_FINAL_CUSTOMER: "mission_final_customer",
    };

    static PREFERENCE_TYPE = {
        ACTIVITY: "activity",
        COMPANY: "company",
        PREFERRED_SKILL: "preferred_skill",
    };

    static FREELANCE_PROFILE_SECTION = {
        PROFILE: "ProfileSection",
        AVAILABILITY: "AvailabilitySection",
        SKILL: "SkillSection",
        REFERRAL: "ReferralSection",
        ASSIGNMENT: "AssignmentSection",
        OTHER_ASSIGNMENT: "OtherAssignmentSection",
        PREFERENCE: "PreferenceSection",
        INFORMATION_DOCUMENT: "InformationAndDocumentSection",
        OFFER_ANSWER: "OfferAnswerSection",
    };

    static OFFER_STEP = {
        DESCRIPTION: "description",
        INFORMATION: "information",
        PROCESS: "process",
        DIFFUSION: "diffusion",
    };

    static FREELANCE_PROFILE_TAGS_TYPE = {
        DEFAULT: "default",
        REPORTING_ENTITY: "reporting_entity",
        LEGAL_ENTITY: "legal_entity",
        COUNTRY: "country",
    };

    static FREELANCE_PROFILE_PERMISSION = {
        FULL: "full",
        NOTE: "note",
    };

    static INTERACTION_TYPE = {
        CALL: "call",
        EMAIL: "email",
        MESSAGE: "message",
        MEETING: "meeting",
        SMARTEEM_EVENT: "smarteem_event",
        EVENT: "event",
        NOTE: "note",
    };
}

export default Functions;
