import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {AppLayout} from "../../../components/UI/AppLayout";
import {DisplayBar} from "../../../components/UI/DisplayBar";
import {CandidateTable} from "./CandidateTable";
import {Box, Stack} from "@mui/material";

export const CandidateBoard = () => {
    const {t} = useTranslation();

    const [rowCount, setRowCount] = useState(0);
    const [displayType, setDisplayType] = useState("List");

    const displayItems = [
        {key: 'List', name: 'All candidates'}
    ];

    return (
        <AppLayout className="content-full-height">
            <Stack>
                <div className="pageheader">
                    <h1 className="pagetitle">
                        {t("Candidates")}
                        <p className="header-data-row-count">{rowCount}</p>
                    </h1>
                </div>
                <DisplayBar
                    displayItems={displayItems}
                    displayType={displayType}
                />
            </Stack>
            <Box className="table-layout">
                <CandidateTable onListChange={setRowCount}/>
            </Box>
        </AppLayout>
    );
};