import CryptoJS from "crypto-js";
const secretKey = process.env.REACT_APP_CRYPTO_SECRET_KEY;

export const requestApi = async (url, method = 'GET', data = {}, token = "") => {
    let options = {
        method: method,
        headers: {
            "Content-Type": "application/json",
            authorization: token
        }
    };

    if (Object.keys(data).length) {
        options.body = JSON.stringify(data);
    }
    const response = await fetch(url, options);

    let responseData = await response.json();

    if (method !== 'GET') {
        return responseData;
    }

    if (responseData?.encryptedData) {
        responseData = JSON.parse(CryptoJS.AES.decrypt(responseData.encryptedData, secretKey).toString(CryptoJS.enc.Utf8));
    }

    if (!response.headers.has('content-range')) {
        return responseData;
    }

    const contentRange = response.headers.get('content-range');
    const contentRangeData = contentRange.split("/");

    return {
        count: parseInt(contentRangeData[1]),
        data: responseData
    };
}