import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {AppLayout} from "../components/UI/AppLayout";
import {DisplayBar} from "../components/UI/DisplayBar";
import {FreelanceBoard} from "../components/Forms/Reporting/FreelanceBoard";
import {ExpenseBoard} from "../components/Forms/Reporting/ExpenseBoard";
import "./page.css";
import "./reportingboard.css";

export const ReportingBoard = () => {
    const {t} = useTranslation();
    const [displayType, setDisplayType] = useState("Freelancers");

    const displayItems = [
        {
            key: 'Freelancers',
            name: 'Freelancers'
        },
        {
            key: 'Expenses',
            name: 'Expenses'
        }
    ];

    const handleDisplayMenuClick = (newDisplay) => {
        setDisplayType(newDisplay);
    };

    return (
        <AppLayout>
            <div className="pageheader">
                <h1 className="pagetitle">{t("Reporting")}</h1>
            </div>
            <DisplayBar
                displayItems={displayItems}
                displayType={displayType}
                onDisplayMenuClick={handleDisplayMenuClick}
            />
            <div className="content-layout" style={{gap: "10px"}}>
                {displayType === 'Freelancers' && <FreelanceBoard/>}
                {displayType === 'Expenses' && <ExpenseBoard/>}
            </div>
        </AppLayout>
    );
};
