import {Box, Button, Grid, List, ListItem, ListItemAvatar, ListItemText, Typography} from "@mui/material";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {FieldHolder} from "../../../FieldHolder";
import {UserCard} from "../../../../UI/UserCard";
import {
    modifyShortlistedFreelance
} from "../../../../../services/missionShortlistedFreelance";
import {useSelector} from "react-redux";
import _ from "lodash";

export const ProposeMissionForm = ({shortlistedFreelances, onSaveFinish}) => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        message: ''
    });

    const handleChange = (e) => {
        const {name, value} = e.target;
        setData({...data, [name]: value});
    };

    const handleSubmit = async () => {
        const _shortlistedFreelances = _.cloneDeep(shortlistedFreelances);

        setLoading(true);
        for (const shortlistedFreelance of _shortlistedFreelances) {
            delete shortlistedFreelance.FreelanceProfile;
            delete shortlistedFreelance.ProposalSender;

            await modifyShortlistedFreelance(
                shortlistedFreelance.id,
                {
                    ...shortlistedFreelance,
                    message: data.message,
                    notification: true
                },
                token
            );
        }

        setLoading(false);
        onSaveFinish && onSaveFinish(true);
    }

    return <Box sx={{width: {md: "25vw"}}}>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="label">{t("Freelancers")}</Typography>
                <List>
                    {shortlistedFreelances.map((shortlistedFreelance) => {
                        const freelance = shortlistedFreelance.FreelanceProfile;
                        return (
                            <ListItem key={freelance.id} sx={{pl: 0}}>
                                <ListItemAvatar>
                                    <UserCard user={freelance.User} displaySkeleton={true} hideLabel={true}/>
                                </ListItemAvatar>
                                <ListItemText primary={freelance.User.fullName}/>
                            </ListItem>
                        );
                    })}
                </List>
            </Grid>
            <Grid item xs={12}>
                <FieldHolder
                    fielddesc={t("Message to freelancers")}
                    type="multiline"
                    name="message"
                    id="message"
                    value={data.message}
                    onChange={handleChange}
                    canModify={true}
                />
            </Grid>
            <Grid item xs={12} sx={{textAlign: "center"}}>
                <Button
                    disabled={loading}
                    variant="contained"
                    color="secondary"
                    onClick={handleSubmit}
                >{t("Propose the assignment")}</Button>
            </Grid>
        </Grid>
    </Box>
};