import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {AppLayout} from "../../components/UI/AppLayout";

import "../page.css";
import {Table} from "../../components/Tables/Table";
import {DisplayBar} from "../../components/UI/DisplayBar";
import Utils from "../../utils/Utils";
import Functions from "../../utils/Functions";
import {fetchRequests} from "../../services/enterpriseCollaborationRequestService";
import {Box, Stack} from "@mui/material";

const pageSize = parseInt(process.env.REACT_APP_TABLE_DEFAULT_PAGE_SIZE);

export const CompanyBoard = () => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);
    const connectedUser = useSelector((state) => state.auth);

    const tableRef = useRef();
    const [displayType, setDisplayType] = useState("List");
    const [rowCount, setRowCount] = useState(0);
    const [data, setData] = useState([]);
    const defaultFilterConfigs = {
        sort: '',
        range: JSON.stringify([0, pageSize - 1]),
        filter: ''
    };
    const [filterConfigs, setFilterConfigs] = useState(defaultFilterConfigs);

    const displayItems = [
        {key: 'List', name: 'List'}
    ];
    const isFreelanceSession = connectedUser.sessionType === Functions.SESSION_TYPE_FREELANCE;

    const columns = useMemo(() => {
        return [
            {
                field: 'companyName',
                type: 'string',
                headerName: t("Company"),
                minWidth: 250,
                flex: 2.5,
                headerAlign: 'center',
                align: 'left',
                renderCell: ({row}) => {
                    return row.companyName;
                }
            },
            {
                field: 'CreatorUserId',
                headerName: t("Status"),
                minWidth: 350,
                flex: 3.5,
                headerAlign: 'center',
                align: 'left',
                renderCell: ({row}) => {
                    return row.CreatorUserId === connectedUser.userId
                        ? t("Share my information and documents with a company")
                        : t("My profile has been added to the company's freelance pool");
                }
            },
            {
                field: 'createdAt',
                type: 'date',
                headerName: t("Date"),
                minWidth: 250,
                flex: 2.5,
                headerAlign: 'center',
                align: 'center',
                valueGetter: (params, row) => row.createdAt && new Date(row.createdAt),
                renderCell: ({row}) => {
                    return Utils.formatDate(row.createdAt);
                }
            },
        ];
    }, [connectedUser?.id]);

    const loadRequests = useCallback((query = {}) => {
        try {
            if (isFreelanceSession) {
                fetchRequests(query, token).then((fetchedData) => {
                    if (!fetchedData?.error) {
                        setRowCount(fetchedData.count);
                        setData(fetchedData.data);
                    }
                });
            }
        } catch (error) {
            console.error(error);
        }
    }, [token, isFreelanceSession]);

    useEffect(() => {
        loadRequests(filterConfigs);
    }, [filterConfigs, loadRequests]);

    const handleDisplayMenuClick = (newDisplay) => {
        tableRef.current.initTable();
        setDisplayType(newDisplay);
        setFilterConfigs(defaultFilterConfigs);
    };

    const handleDataGridChange = (filterConfigs) => {
        setFilterConfigs(filterConfigs);
    };

    return (
        <AppLayout className="content-full-height">
            <Stack>
                <div className="pageheader">
                    <h1 className="pagetitle">
                        {t("My companies")}
                        <p className="header-data-row-count">{rowCount}</p>
                    </h1>
                </div>
                <DisplayBar
                    displayItems={displayItems}
                    displayType={displayType}
                    onDisplayMenuClick={handleDisplayMenuClick}
                />
            </Stack>
            <Box className="table-layout" sx={{width: {xs: '100%', md: 'calc(57% + 302px)'}}}>
                <Table
                    ref={tableRef}
                    columns={columns}
                    data={data}
                    rowCount={rowCount}
                    pageSize={pageSize}
                    filterConfigs={filterConfigs}
                    onChange={handleDataGridChange}
                />
            </Box>
        </AppLayout>
    );
};

