import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {AppLayout} from "../../../components/UI/AppLayout";
import {useParams} from "react-router-dom";
import {fetchOffer} from "../../../services/recruitment";
import {OfferForm} from "../../../components/Forms/Recruitment/Offer/OfferForm";
import {Box, Stack, Typography} from "@mui/material";
import {MdCircle} from "react-icons/md";
import "./offerdetail.css";
import CopyLinkButton from "../../../components/Button/CopyLinkButton";


export const OfferDetail = () => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);
    const {id} = useParams();

    const [offerId, setOfferId] = useState(id);
    const [offer, setOffer] = useState({
        status: 'New',
        RecruitmentCriteria: id ? null : [],
        RecruitmentQuestions: id ? null : [],
        RecruitmentPipelines: id ? null : [],
        steps: []
    });
    const [triggerApi, setTriggerApi] = useState(true);

    const titleRef = useRef(null);

    useEffect(() => {
        try {
            if (offerId && triggerApi) {
                fetchOffer(offerId, token).then((fetchedOffer) => {
                    if (!fetchedOffer?.error) {
                        if (fetchedOffer.RecruitmentOfferSkills) {
                            fetchedOffer.RecruitmentOfferSkills = fetchedOffer.RecruitmentOfferSkills.map(os => os.Skill);
                        }

                        setOffer(fetchedOffer);
                        setTriggerApi(false);
                    }
                });
            }
        } catch (error) {
            console.error(error);
        }
    }, [offerId, triggerApi, token]);

    const getOfferStatusIcon = () => {
        let statusClass = offer.status.toLowerCase();
        statusClass = statusClass.replace(/ /g, '-');
        return <MdCircle className={`status-${statusClass}`} size={20}/>
    };

    const pageHeaderStyle = {width: {xs: '100%', md: 'calc(57% + 302px)'}};

    return (
        <AppLayout>
            <Box className="pageheader" sx={pageHeaderStyle}>
                <Stack direction="row" alignItems="center" sx={{maxWidth: '70%', gap: '15px'}}>
                    {getOfferStatusIcon()}
                    <h1 className="pagetitle" ref={titleRef} style={{minHeight: '40px'}}>
                        {offer?.title ?? t("New offer")}
                    </h1>
                </Stack>
                <Box className="pageaction" sx={{minHeight: "54px"}}>
                    {offer.id && (
                        <CopyLinkButton
                            link={`${window.location.origin}/offer/${offer.id}`}
                            label="Copy offer link"
                            variant="outlined"
                            size="large"
                        />
                    )}
                </Box>
            </Box>
            <Box sx={pageHeaderStyle}>
                {Boolean(offer?.id || !id) && (
                    <OfferForm
                        offerId={offerId}
                        setOfferId={setOfferId}
                        titleRef={titleRef}
                        offer={offer}
                        setTriggerApi={setTriggerApi}
                    />
                )}
            </Box>
        </AppLayout>
    );
};