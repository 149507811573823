import React, {useEffect, useState} from "react";
import {AppLayout} from "../../components/UI/AppLayout";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {QuoteDetailSection} from "../../components/Forms/Mission/Sections/QuoteSection/QuoteDetailSection";
import {fetchQuote} from "../../services/quoteService";

export const QuoteDetail = () => {
    const {id} = useParams();
    const token = useSelector((state) => state.auth.token);
    const sessionType = useSelector((state) => state.auth.sessionType);

    const [quote, setQuote] = useState({});

    useEffect(() => {
        (async () => {
            if (typeof id !== "undefined") {
                const fetchedQuote = await fetchQuote(id, token);
                setQuote(fetchedQuote);
            }
        })();
    }, [id, token]);

    return (
        <>
            <AppLayout>
                <QuoteDetailSection
                    selectedQuote={quote}
                    setSelectedQuote={setQuote}
                    sessionType={sessionType}
                    isSection={false}
                />
            </AppLayout>
        </>
    );
};