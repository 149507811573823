import React, {useState} from 'react';
import {IconButton, Box} from '@mui/material';
import {styled} from '@mui/system';
import {MdCheck, MdContentCopy} from "react-icons/md";
import {useTranslation} from "react-i18next";

const HoverBox = styled(Box)({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '100%'
});

const CopyButton = styled(IconButton)(({theme}) => ({
    position: 'absolute',
    top: 'calc(50% - 12px)',
    right: '-8px',
    display: 'none',
    '&.visible': {
        display: 'inline-flex',
    },
}));

const HoverCopyButton = ({children, textToCopy}) => {
    const {t} = useTranslation();
    const [isHovered, setIsHovered] = useState(false);
    const [icon, setIcon] = useState(<MdContentCopy/>);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const handleCopy = (e) => {
        e.preventDefault();
        e.stopPropagation();

        navigator.clipboard.writeText(textToCopy);
        setIcon(<MdCheck />);
        setTimeout(() => {
            setIcon(<MdContentCopy />);
        }, 1000);
    };

    return (
        <HoverBox onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {children}
            <CopyButton
                className={isHovered ? 'visible' : ''}
                title={isHovered ? t("Copy") : ""}
                size="small"
                onClick={handleCopy}
            >
                {icon}
            </CopyButton>
        </HoverBox>
    );
};

export default HoverCopyButton;
